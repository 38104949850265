import React from 'react'
import ReactDOM from 'react-dom'
import { ToastContainer } from 'react-toastify'
import Container from './pages/Container'
import { Provider } from 'react-redux'
import store from './redux/store'

import './assets/styles/styles.scss'
import 'react-toastify/dist/ReactToastify.css'
import session from './utils/session'

class App extends React.Component {
    constructor(props) {
        super(props)
        this.init()
    }

    init = async () => {
        await session.init()
    }

    render() {
        return (
            <Provider store={store}>
                <ToastContainer />
                <Container />
            </Provider>
        )
    }
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
)

import React from 'react'

// import { Container } from './styles';

function Title({ name }) {
    return (
        <div className="row">
            <div className="col-12">
                <div className="page-title-box">
                    <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                            <li className="breadcrumb-item">
                                <a href="/">Home</a>
                            </li>
                            <li className="breadcrumb-item">
                                <a href="/profile">Perfil</a>
                            </li>
                            <li className="breadcrumb-item active">Perfil - {name}</li>
                        </ol>
                    </div>
                    <h4 className="page-title">Perfil - {name}</h4>
                </div>
            </div>
        </div>
    )
}

export default Title

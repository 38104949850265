import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import Charts from 'react-apexcharts'
import { useForm, Controller } from 'react-hook-form'
import { AiOutlineReload } from 'react-icons/ai'
import DatePick from '../../../../components/DatePicker'
import { ServiceUsers } from '../../../../services/api'

function Graphics({ id }) {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        control,
    } = useForm({
        defaultValues: {
            date: {
                created_from: DateTime.now().plus({ day: -30 }).toJSDate(),
                created_to: DateTime.now().toJSDate(),
            },
        },
    })
    const [loading, setLoading] = useState(true)

    const [state, setState] = useState({
        options: {
            chart: {
                type: 'bar',
                height: 650,
                toolbar: {
                    show: false,
                },
            },
            stroke: {
                curve: 'smooth',
            },
            xaxis: {
                type: 'string',
                categories: [1, 2, 3, 4, 5, 6, 7, 8],
                tooltip: {
                    enabled: !1,
                },
                axisBorder: {
                    show: !1,
                },
                labels: {
                    show: false,
                    formatter: function (value) {
                        return DateTime.fromISO(value).toFormat('dd/LL/yyyy')
                    },
                },
            },
            legend: {
                show: !1,
            },
            dataLabels: {
                enabled: !1,
            },
            stroke: {
                curve: 'smooth',
                width: 4,
            },
        },
        series: [
            {
                type: 'column',
                name: 'Posts',
                data: [10, 20, 5, 15, 10, 20, 15, 25],
            },
        ],
    })

    const loadData = async (date = { created_from: DateTime.now().plus({ day: -30 }).toJSDate(), created_to: DateTime.now().toJSDate() }) => {
        setLoading(true)
        ServiceUsers.getGraphic(id, { created_from: DateTime.fromJSDate(date.created_from).toSQLDate(), created_to: DateTime.fromJSDate(date.created_to).toSQLDate() })
            .then((resp) => {
                const dataChart = resp.data
                let set_value = (dataChart || []).map((element) => element.total)
                let set_data = (dataChart || []).map((element) => DateTime.fromSQL(element.day, { zone: 'utc' }).toISODate())
                let saida = {
                    ...state,
                    options: {
                        ...state.options,
                        xaxis: {
                            ...state.options.xaxis,
                            type: 'date',
                            categories: set_data,
                        },
                    },
                    series: [{ ...state.series[0], data: set_value }],
                }
                setState(saida)
                setLoading(false)
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => loadData(), [])

    const onSubmit = async (data) => {
        await loadData(data.date)
    }

    return (
        <div className="card">
            <div className="card-body">
                {loading ? (
                    <div className="d-flex flex-aligns-center justify-content-center">
                        <div class="spinner-border text-primary" role="status"></div>{' '}
                    </div>
                ) : (
                    <>
                        <div className="d-flex flex-column justify-content-between align-items-left mb-3">
                            <h4 className="header-title mb-3">Numero de posts por dia</h4>
                            <form className="d-flex" onSubmit={handleSubmit(onSubmit)}>
                                <div className="input-group">
                                    <Controller
                                        name="date"
                                        control={control}
                                        render={({ field }) => (
                                            <DatePick
                                                start={field.value.created_from}
                                                end={field.value.created_to}
                                                onStart={(e) => field.onChange({ created_from: e, created_to: field.value.created_to })}
                                                onEnd={(e) => field.onChange({ created_from: field.value.created_from, created_to: e })}
                                            />
                                        )}
                                    />
                                    <button class="input-group-text bg-primary text-white ml-2">
                                        <AiOutlineReload />
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div>
                            <Charts options={state.options} series={state.series} type="bar" width="100%" />
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default Graphics

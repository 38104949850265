import * as actionTypes from './actionTypes'

export function setOutSideStack() {
    return {
        type: actionTypes.LOGOUT,
        payload: {},
    }
}

export function setInsideStack(user, token) {
    return {
        type: actionTypes.LOGIN,
        payload: { token, user },
    }
}

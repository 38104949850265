import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import TabBarElement from './Element'
import Pagination from '@material-ui/lab/Pagination'
import { ServiceUsers } from '../../../../services/api/users'

const LIMIT = 10

function TabBar({}) {
    const [list, setList] = React.useState([])
    const [type, setType] = React.useState('all')
    const [total, setTotal] = React.useState(0)
    const [page, setPage] = React.useState(0)
    const [loading, setLoading] = React.useState(true)
    const [query_search, setQuerySearch] = React.useState('')

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        control,
    } = useForm()

    const onSubmit = async (data) => {
        setQuerySearch(data.search)
        await loadPage(0, 'all', data.search)
    }

    const loadPage = async (pageNumber = page, typePage = type, query = query_search) => {
        setLoading(true)
        ServiceUsers.getUsers({ offset: pageNumber * LIMIT, limit: LIMIT, type: typePage, query: query ? query : undefined })
            .then((res) => {
                setList(res.data.users)
                setTotal(res.data.total)
                setPage(pageNumber + 1)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        loadPage()
    }, [])

    const HandlerChangePage = async (event, value) => {
        await loadPage(value - 1)
    }

    const HandlerType = async (event) => {
        await loadPage(0, event.target.value)
        setType(event.target.value)
    }

    return (
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-body p-0">
                        <ul className="nav nav-tabs nav-bordered">
                            <li className="nav-item">
                                <button onClick={HandlerType} value="all" className={'nav-link py-2 ' + (type === 'all' ? 'active' : '')}>
                                    Todos
                                </button>
                            </li>
                            <li className="nav-item">
                                <button onClick={HandlerType} value="last" className={'nav-link py-2 ' + (type === 'last' ? 'active' : '')}>
                                    Recentes
                                </button>
                            </li>
                        </ul>
                        {/* end nav*/}
                        <div className="tab-content">
                            <div className="tab-pane show active p-3" id="newpost">
                                {/* start search box */}
                                <div className="app-search">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="mb-2 position-relative">
                                            <Controller
                                                control={control}
                                                name="search"
                                                render={({ field }) => <input {...field} type="text" className="form-control" placeholder="Digite o Nome ou Username.." />}
                                            />
                                            <span className="mdi mdi-magnify search-icon" />
                                        </div>
                                    </form>
                                </div>

                                {loading ? (
                                    <div className="text-center mt-4">
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        {list.map((user) => (
                                            <TabBarElement user={user} />
                                        ))}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="p-4 d-flex justify-content-end">
                        <Pagination count={Math.ceil(total / LIMIT)} onChange={HandlerChangePage} page={page} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TabBar

import React, { useEffect } from 'react'
import { Button, Form } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { ServicePosts } from '../../../../services/api'

// import { Container } from './styles';

function ModalEdit({ post }) {
    if (!post) return null

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        control,
        setValue,
    } = useForm()

    const onSubmit = (data) => {
        ServicePosts.update(post.post_id, data)
            .then(() => {
                toast.success('Post atualizado com sucesso!')
                setDataModal(null)
                onLoad(page - 1)
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        if (post) {
            Object.keys(post).forEach((key) => {
                setValue(key, post[key])
            })
        }
    }, [post])

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
                <Form.Label>Título</Form.Label>
                <Controller name="titulo" control={control} render={({ field }) => <Form.Control {...field} />} />
            </Form.Group>
            <Form.Group>
                <Form.Label>Descrição</Form.Label>
                <Controller name="descricao" control={control} render={({ field }) => <Form.Control {...field} />} />
            </Form.Group>
            <Form.Group className="d-flex flex-row justify-content-between">
                <Controller
                    name="original"
                    control={control}
                    render={({ field }) => (
                        <Form.Check type="switch" id="custom-switch" label="Original ?" checked={Boolean(field.value)} onChange={(e) => field.onChange(e.target.checked)} />
                    )}
                />
                <Controller
                    name="nsfw"
                    control={control}
                    render={({ field }) => (
                        <Form.Check type="switch" id="custom-switch-2" label="NSFW ?" checked={Boolean(field.value)} onChange={(e) => field.onChange(e.target.checked)} />
                    )}
                />
            </Form.Group>
            <div>
                <Button type="submit">Salvar</Button>
            </div>
        </Form>
    )
}

export default ModalEdit

import React from 'react'
import { Route, Switch } from 'react-router'
import Dashboard from '../pages/Dashboard'
import ListProfile from '../pages/ListProfile'
import Notification from '../pages/Notification'
import Profile from '../pages/Profile'
import Feed from '../pages/Feed'
import PostDetails from '../pages/PostDetails'

// import { Container } from './styles';

function InsideStack() {
    return (
        <Switch>
            <Route path="/feed" component={Feed} />
            <Route path="/notification" component={Notification} />
            <Route path="/profile/:id" component={Profile} />
            <Route path="/profile" component={ListProfile} />
            <Route path="/post/:id" component={PostDetails} />
            <Route path="/" component={Dashboard} />
        </Switch>
    )
}

export default InsideStack

import { DateTime } from 'luxon'
import React from 'react'
import { URL_BASE, URL_FILES } from '../../../../services/axios.service'

// import { Container } from './styles';

function TabBarElement({ user }) {
    return (
        <div className="row">
            <div className="col">
                <a href={'/profile/' + user.id} className="text-body">
                    <div className="d-flex align-items-center mt-1 p-2">
                        <img src={user.avatar ? URL_FILES + '/user/' + user.avatar : URL_BASE + '/images/pepeavatar.png'} className="mr-2 rounded-circle" height={48} />
                        <div className="w-100 overflow-hidden">
                            <h5 className="mt-0 mb-0 font-14">
                                <span className="float-end text-muted font-12">Ult. acesso</span>
                                {user.user}
                            </h5>
                            <p className="mt-1 mb-0 text-muted font-14">
                                <span className="float-end text-end">{DateTime.fromSQL(user.data_ultimo_login).toFormat('dd/LL hh:mm:ss')}</span>
                                <span className="w-75">{user.nome}</span>
                            </p>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default TabBarElement

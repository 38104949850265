import React from 'react'
import { Button, Spinner } from 'react-bootstrap'

// import { Container } from './styles';

function CustomButton({ className, loading, children, onClick, variant, icon, type }) {
    return (
        <Button variant={variant} disabled={loading} onClick={!loading ? onClick : null} className={className} type={type}>
            {loading && <span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>}
            {icon && <span className="me-1">{icon}</span>}
            {children}
        </Button>
    )
}

export default CustomButton

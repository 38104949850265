import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import { AiFillGift } from 'react-icons/ai'
import { ServiceDashboard } from '../../../../services/api/dashboard'
import { URL_FILES } from '../../../../services/axios.service'

// import { Container } from './styles';

function RecentActivity() {
    const [feed, setFeed] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        ServiceDashboard.getLastActivities()
            .then((resp) => {
                setFeed(resp.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    return (
        <div className="card">
            <div className="card-body">
                <h4 className="header-title mb-2">Atividades Recentes</h4>
                <div className="timeline-alt pb-0">
                    {feed.map((element) => (
                        <div className="timeline-item">
                            <span className="bg-info-lighten text-info timeline-icon">
                                <AiFillGift size={10} />
                            </span>
                            <div className="timeline-item-info">
                                <a href="#" className="text-info fw-bold d-block">
                                    {element.event}
                                </a>
                                <div className="my-2">
                                    <img src={URL_FILES + '/user/' + element.avatar} alt="table-user" class="me-2 rounded-circle" height="24" />

                                    <a href={'/profile/' + element.user_id}>
                                        <small className="text-semi-bold">@{element.user}</small>{' '}
                                    </a>
                                </div>
                                <small>{element.description}</small>

                                <p className="mb-0 pb-2">
                                    <small className="text-muted">{DateTime.fromSQL(element.created_at).toFormat('dd/LL/yyyy HH:mm:ss')} </small>
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default RecentActivity

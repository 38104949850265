import { DateTime } from 'luxon'
import React, { useEffect } from 'react'
import { FaCommentAlt, FaEdit, FaSmile, FaTrash } from 'react-icons/fa'
import { useParams, useHistory } from 'react-router'
import Swal from 'sweetalert2'
import AboutMe from '../../components/AboutMe'
import CustomModal from '../../components/CustomModal'
import RowLoading from '../../components/RowLoading'
import { ServicePosts, ServiceUsers } from '../../services/api'
import { URL_BASE, URL_FILES } from '../../services/axios.service'
import ModalEdit from './container/ModalEdit'
import Options from './container/Options'
import Post from './container/Post'

// import { Container } from './styles';

function PostDetails() {
    const { id } = useParams()
    const [post, setPost] = React.useState(null)
    const [loading, setLoading] = React.useState(true)
    const [dataEdit, setDataEdit] = React.useState(null)
    const [info, setInfo] = React.useState({})

    const history = useHistory()

    useEffect(() => {
        ServicePosts.getPost(id)
            .then((resp) => {
                setPost(resp.data)
                return ServiceUsers.getUser(resp.data.id_autor)
            })
            .then((res) => {
                setInfo(res.data.info)
                setLoading(false)
            })
            .catch((err) => {})
    }, [])

    const handleDelete = () => {
        const id = post.id_post
        Swal.fire({
            title: 'Tem certeza?',
            text: 'Você não poderá reverter isso!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, apague!',
        }).then((result) => {
            if (result.value) {
                ServicePosts.delete(id)
                    .then(() => {
                        Swal.fire('Apagado!', 'Post foi apagado.', 'success')
                        history.goBack()
                    })
                    .catch(() => {
                        Swal.fire('Erro!', 'Post não pode ser apagado.', 'error')
                    })
            }
        })
    }

    const handlerEdit = () => {
        setDataEdit(post)
    }

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box">
                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">
                                    <a href="javascript: void(0);">Hyper</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="javascript: void(0);">Apps</a>
                                </li>
                                <li className="breadcrumb-item active">Social Feed</li>
                            </ol>
                        </div>
                        <h4 className="page-title">Detalhes do Post</h4>
                    </div>
                </div>
            </div>
            {loading ? (
                <RowLoading />
            ) : (
                <div className="row">
                    <div className="col-3">
                        <AboutMe info={info} />
                    </div>
                    <div className="col-6">
                        <Post post={post} />
                    </div>
                    <div className="col-3">
                        <Options handleDelete={handleDelete} handlerEdit={handlerEdit} />
                    </div>
                </div>
            )}
            <CustomModal open={Boolean(dataEdit)} title="Editar Postagem" onClose={() => setDataEdit(null)}>
                {post?.id_post && <ModalEdit post={post} />}
            </CustomModal>
        </>
    )
}

export default PostDetails

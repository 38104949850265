import { v4 as uuidv4 } from 'uuid'

class Device {
    init() {
        let device_uuid = localStorage.getItem('@memehut:UUID')
        if (device_uuid) return device_uuid

        device_uuid = uuidv4()
        localStorage.setItem('@memehut:UUID', device_uuid)
        return device_uuid
    }
}

export default new Device()

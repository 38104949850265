import React from 'react'
import { URL_BASE, URL_FILES } from '../../../../services/axios.service'
import { DateTime } from 'luxon'
import { FaEdit, FaTrash } from 'react-icons/fa'
// import { Container } from './styles';

function PostFeed({ post }) {
    return (
        <div className="border border-light p-2 mb-3">
            <div className="d-flex">
                <img
                    className="me-2 rounded-circle"
                    src={post.avatar ? URL_FILES + '/user/' + post.avatar : URL_BASE + '/images/pepeavatar.png'}
                    alt="Generic placeholder image"
                    height={32}
                />
                <div>
                    <h5 className="m-0">{post.user}</h5>
                    <p className="text-muted m-0">
                        <small>{DateTime.fromSQL(post.data_criacao).toFormat('dd/LL hh:mm')}</small>
                    </p>
                </div>
            </div>
            <hr class="mt-2 mb-3" />
            {post.titulo && <p>{post.titulo}</p>}
            <div>
                <a href={'/post/' + post.id_post}>
                    <img width="100%" src={URL_FILES + '/posts/' + post.midia} />
                </a>
            </div>
            {post.descricao && <span>{post.descricao}</span>}
            <div className="mt-2">
                <a href="javascript: void(0);" class="btn btn-sm btn-light mr-1">
                    🎉 {post.n_likes}
                </a>
                <a href="javascript: void(0);" class="btn btn-sm btn-light mx-1">
                    💭 {post.n_comentarios}
                </a>
            </div>
        </div>
    )
}

export default PostFeed

import React from 'react'
import NavHeader from '../NavHeader'
import NavLink from '../NavLink'

// import { Container } from './styles';

function MainContainer({ children }) {
    return (
        <>
            <NavHeader />
            <NavLink />
            <main className="container-fluid">{children}</main>
        </>
    )
}

export default MainContainer

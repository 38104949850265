import React, { useEffect } from 'react'
import { Button, Form } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { useForm, Controller } from 'react-hook-form'
import { ServicePosts } from '../../../../services/api/posts'
import CustomModal from '../../../../components/CustomModal'
import PostFeed from './Post'
import { toast } from 'react-toastify'
import { Pagination } from '@material-ui/lab'
import RowLoading from '../../../../components/RowLoading'

const LIMIT = 10

function Feed({ userId }) {
    const [dataModal, setDataModal] = React.useState(null)
    const [list, setList] = React.useState([])
    const [total, setTotal] = React.useState(0)
    const [page, setPage] = React.useState(0)
    const [loading, setLoading] = React.useState(false)

    const onLoad = async (pageNumber = page) => {
        setLoading(true)
        ServicePosts.getPostsUser(userId, { offset: LIMIT * pageNumber, limit: LIMIT })
            .then((res) => {
                setList(res.data.post)
                setTotal(res.data.total)
                setPage(pageNumber + 1)
                setLoading(false)
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        onLoad()
    }, [])

    const HandlerPagination = async (e, value) => {
        await onLoad(value - 1)
    }

    return (
        <div className="card">
            <div className="card-body">
                <div>
                    {loading ? (
                        <div className="text-center">
                            <div className="spinner-border text-primary" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <>
                            {list.map((post) => (
                                <PostFeed post={post} />
                            ))}
                        </>
                    )}

                    <div className="d-flex justify-content-end">
                        <Pagination count={Math.ceil(total / LIMIT)} color="primary" onChange={HandlerPagination} page={page} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Feed

import React from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import * as actionTypes from '../../redux/actionTypes'
import MainContainer from '../../components/MainContainer'
import InsideStack from '../../stacks/InsideStack'
import OutsideStack from '../../stacks/OutsideStack'
import Loading from '../Loading'

function Container({ root }) {
    return (
        <Router>
            {root === actionTypes.ROOT_LOADING && <Loading />}
            {root === actionTypes.ROOT_OUTSIDE && <OutsideStack />}
            {root === actionTypes.ROOT_INSIDE && (
                <MainContainer>
                    <InsideStack />
                </MainContainer>
            )}
        </Router>
    )
}

function mapProps(state) {
    return {
        root: state.app.root,
    }
}

export default connect(mapProps)(Container)

import React from 'react'
import { FaBan, FaExclamation, FaUserNurse, FaUserTag } from 'react-icons/fa'

// import { Container } from './styles';

function Options({ banned, limited, onBanner, onLimited, onPermission }) {
    return (
        <div className="card">
            <div className="card-body p-2">
                <div className="list-group list-group-flush my-2">
                    <a href="javascript:void(0);" onClick={() => null} className="list-group-item list-group-item-action border-0 disabled">
                        <FaUserTag size={15} className="me-1" /> Tags
                    </a>
                    <a href="javascript:void(0);" onClick={onPermission} className="list-group-item list-group-item-action border-0">
                        <FaUserNurse size={15} className="me-1" /> Permissões
                    </a>
                    {limited ? (
                        <a href="javascript:void(0);" onClick={() => onLimited(false)} className="list-group-item list-group-item-action border-0">
                            <FaExclamation size={15} className="me-1" /> Deslimitar
                        </a>
                    ) : (
                        <a href="javascript:void(0);" onClick={() => onLimited(true)} className="list-group-item list-group-item-action border-0">
                            <FaExclamation size={15} className="me-1" /> Limitar
                        </a>
                    )}

                    {banned ? (
                        <a href="javascript:void(0);" onClick={() => onBanner(false)} className="list-group-item list-group-item-action border-0">
                            <FaBan size={15} className="me-1" /> Desbanir
                        </a>
                    ) : (
                        <a href="javascript:void(0);" onClick={() => onBanner(true)} className="list-group-item list-group-item-action border-0 text-danger">
                            <FaBan size={15} className="me-1" /> Banir
                        </a>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Options

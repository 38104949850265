import React from 'react'
import { FaPencilAlt } from 'react-icons/fa'
import AboutMe from '../../../../components/AboutMe'

// import { Container } from './styles';

function UserAboutMe({ data, onEdit }) {
    const { info, n_follow, n_following, n_post } = data
    return (
        <>
            <AboutMe info={info} />
            <div className="card bg-primary">
                <div className="card-body profile-user-box">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div>
                                        <h4 className="mt-1 mb-1 text-white">{info.nome}</h4>
                                        <p className="font-13 text-white-50"> @{info.user}</p>
                                        <ul className="mb-0 list-inline text-light">
                                            <li className="list-inline-item me-3">
                                                <h5 className="mb-1">{n_follow}</h5>
                                                <p className="mb-0 font-13 text-white-50">Numero de seguindo</p>
                                            </li>
                                            <li className="list-inline-item">
                                                <h5 className="mb-1">{n_following}</h5>
                                                <p className="mb-0 font-13 text-white-50">Numero de seguidores</p>
                                            </li>
                                            <li className="list-inline-item">
                                                <h5 className="mb-1">{n_post}</h5>
                                                <p className="mb-0 font-13 text-white-50">Numero de Posts</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="text-right mt-sm-0 mt-3 text-sm-end">
                                <button type="button" className="btn btn-light" onClick={onEdit}>
                                    <FaPencilAlt /> Editar Perfil
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserAboutMe

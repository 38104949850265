const { default: axios } = require('axios')
import { io } from 'socket.io-client'
const URL_FILES = 'https://memehut.com.br/api'
const URL_BASE = 'https://memehut.com.br'

const application = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
})

const socket = io(process.env.REACT_APP_SOCKET_URL)

socket.on('connect', () => {
    console.log('connected')
})

export { socket, application, URL_FILES, URL_BASE }

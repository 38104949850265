import React from 'react'
import Logo from '../../assets/images/logo.png'
// import { Container } from './styles';

function NavHeader() {
    return (
        <nav className="navbar navbar-expand-md navbar-dark bg-dark topnav-navbar">
            <div className="container-fluid">
                <a className="navbar-brand" href="#">
                    <img src={Logo} height={'23px'} className="mr-3" />
                    MemeHut Admin
                </a>
            </div>
        </nav>
    )
}

export default NavHeader

import React, { useState, useEffect } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './styles.scss'
import ptBr from 'date-fns/locale/pt-BR'
registerLocale('pt-BR', ptBr)

const DatePick = ({ label, start, end, onStart, onEnd }) => {
    const [startDate, setStartDate] = useState(start)
    const [endDate, setEndDate] = useState(end)

    const ExampleCustomInput = ({ value, onClick }) => <input className="form-control form-control-light" value={value ? value : 'dd/mm/aaaa'} onClick={onClick} />

    useEffect(() => {
        console.log({ endDate, startDate })
        if (onStart) onStart(startDate)
        if (onEnd) onEnd(endDate)
    }, [])

    return (
        <div className="datepicker">
            {label && <label>{label}</label>}

            <div className="picker">
                <DatePicker
                    locale="pt-BR"
                    dateFormat="dd/MM/yyyy"
                    customInput={<ExampleCustomInput />}
                    selected={startDate}
                    onChange={(date) => {
                        setStartDate(date)
                        if (onStart) onStart(date)
                    }}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={new Date()}
                    placeholderText="teste"
                />
                <div className="mx-1" />
                <DatePicker
                    locale="pt-BR"
                    dateFormat="dd/MM/yyyy"
                    customInput={<ExampleCustomInput />}
                    selected={endDate}
                    onChange={(date) => {
                        setEndDate(date)
                        if (onEnd) onEnd(date)
                    }}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={new Date()}
                    placeholderText="dd/mm/aaaa"
                    popperPlacement="top-end"
                />
            </div>
        </div>
    )
}

export default DatePick

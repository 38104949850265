import React from 'react'
import { FaTelegramPlane } from 'react-icons/fa'
import { useForm, Controller } from 'react-hook-form'
import { ServiceNotification } from '../../services/api/notification'
import { Form, Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

// import { Container } from './styles';

const schema = yup
    .object({
        title: yup.string().required('Campo Obrigatório'),
        message: yup.string().required('Campo Obrigatório'),
    })
    .required()

function Notification() {
    const [saving, setSaving] = React.useState(false)
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        control,
        reset,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            title: '',
            message: '',
        },
    })

    const onSubmit = (data) => {
        setSaving(true)
        ServiceNotification.send(data)
            .then((resp) => {
                toast.success('Notificação enviada com sucesso!')
                reset()
            })
            .catch((err) => console.log(err))
            .finally(() => setSaving(false))
    }

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box">
                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                                <li className="breadcrumb-item active">Notificação</li>
                            </ol>
                        </div>
                        <h4 className="page-title">Notificação</h4>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 offset-md-3">
                    <div className="card">
                        <div className="card-body p-4">
                            <h4>Envio de Notificação</h4>
                            <p class="text-muted font-14">
                                Envie notificação para <b>todos os usuarios</b> do app
                            </p>
                            <form onSubmit={handleSubmit(onSubmit)} className="comment-area-box">
                                <div className="form-group">
                                    <label>Titulo</label>
                                    <Controller
                                        name="title"
                                        control={control}
                                        render={({ field }) => <Form.Control isInvalid={errors.title} placeholder="Escreva seu Titulo" {...field} />}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.title?.message}</Form.Control.Feedback>
                                </div>
                                <div className="form-group">
                                    <label>Mensagem</label>
                                    <Controller
                                        name="message"
                                        control={control}
                                        render={({ field }) => <Form.Control as="textarea" isInvalid={errors.message} rows={4} placeholder="Escreva a sua mensagem" {...field} />}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.message?.message}</Form.Control.Feedback>
                                </div>
                                <div className="d-flex justify-content-end align-items-center">
                                    <button type="submit" className="btn btn-sm btn-primary" disabled={saving}>
                                        {saving && <span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>}
                                        <FaTelegramPlane size={20} className="mr-2" />
                                        Enviar
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Notification

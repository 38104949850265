import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { AiOutlineLike, AiOutlineUser, AiOutlineUsergroupAdd, AiOutlineReload, AiOutlineFileImage } from 'react-icons/ai'
import { Spinner } from 'react-bootstrap'
import DatePick from '../../components/DatePicker'
import { ServiceDashboard } from '../../services/api'
import CardDashboard from './container/CardDashboard'
import Sessions from './container/Sessions'
import Posts from './container/Posts'
import RecentActivity from './container/RecentActivity'
import { socket } from '../../services/axios.service'
import { FaGhost } from 'react-icons/fa'

function Dashboard() {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({})
    const [usersOnline, setUsersOnline] = useState(0)

    const { register, handleSubmit, watch, errors, control } = useForm({
        defaultValues: {
            date: {
                created_from: DateTime.now().plus({ day: -7 }).toJSDate(),
                created_to: DateTime.now().toJSDate(),
            },
        },
    })

    const onSubmit = ({ date }) => {
        console.log({ created_from: DateTime.fromJSDate(date.created_from).toSQLDate(), created_to: DateTime.fromJSDate(date.created_to).toSQLDate() })
        setLoading(true)
        ServiceDashboard.getDashboard({ created_from: DateTime.fromJSDate(date.created_from).toSQLDate(), created_to: DateTime.fromJSDate(date.created_to).toSQLDate() })
            .then((resp) => {
                setData(resp.data)
                setLoading(false)
            })
            .catch((err) => {})
    }

    useEffect(() => {
        setLoading(true)
        const params = {
            created_from: DateTime.now().plus({ day: -7 }).toSQLDate(),
            created_to: DateTime.now().toSQLDate(),
        }
        ServiceDashboard.getDashboard(params)
            .then((resp) => {
                setData(resp.data)
                setLoading(false)
            })
            .catch((err) => {})

        socket.on('USER_ONLINE_NOW', (data) => {
            setUsersOnline(data.total)
            console.log(data)
        })
    }, [])

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box">
                        <div className="page-title-right">
                            <form className="d-flex" onSubmit={handleSubmit(onSubmit)}>
                                <div className="input-group">
                                    <Controller
                                        name="date"
                                        control={control}
                                        render={({ field }) => (
                                            <DatePick
                                                start={field.value.created_from}
                                                end={field.value.created_to}
                                                onStart={(e) => field.onChange({ created_from: e, created_to: field.value.created_to })}
                                                onEnd={(e) => field.onChange({ created_from: field.value.created_from, created_to: e })}
                                            />
                                        )}
                                    />

                                    <button class="input-group-text bg-info border-info text-white ml-2">
                                        <AiOutlineReload />
                                    </button>
                                </div>
                            </form>
                        </div>
                        <h4 class="page-title">Analytics</h4>
                    </div>
                </div>
            </div>

            {!loading ? (
                <div className="row">
                    <div className="col-xl-3 col-lg-4">
                        <CardDashboard title="Usuarios Unicos Ativos" value={data.active_user_today} icon={<AiOutlineUser className="float-end" />} />
                        <CardDashboard title="Usuarios Novos" value={data.new_user} icon={<AiOutlineUsergroupAdd className="float-end" />} />
                        <CardDashboard title="Postagens realizadas" value={data.new_post} icon={<AiOutlineFileImage className="float-end" />} />
                    </div>
                    <div className="col-xl-9 col-lg-8">
                        <Sessions dataChart={data.posts} />
                    </div>
                </div>
            ) : (
                <div style={{ minHeight: '440px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Spinner animation="border" role="status"></Spinner>
                </div>
            )}
            <div className="d-flex align-items-center p-3 text-white-50 bg-purple rounded mb-4">
                <FaGhost size={48} color="#fff" className="mr-3" />
                {/* <img className="mr-3" src="https://getbootstrap.com/docs/4.0/assets/brand/bootstrap-outline.svg" alt width={48} height={48} /> */}
                <div className="lh-100">
                    <h6 className="mb-0 text-white lh-100">
                        Usuarios online agora <b>{usersOnline}</b>
                    </h6>
                    <small>Usuarios unicos que acessaram o app hoje : {data.active_user_today}</small>
                </div>
            </div>

            <div className="row">
                <div className="col-8">
                    <Posts />
                </div>
                <div className="col-4">
                    <RecentActivity />
                </div>
            </div>
        </>
    )
}

export default Dashboard

import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { Button, ButtonGroup, Form, Spinner } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

import Title from './container/Title'
import AboutMe from './container/UserAboutMe'
import Options from './container/Options'
import Feed from './container/Feed'
import CustomModal from '../../components/CustomModal'
import { ServiceUsers, ServicePosts } from '../../services/api'
import { FaPencilAlt } from 'react-icons/fa'
import RowLoading from '../../components/RowLoading'
import Graphics from './container/Graphics'
import ModalPermission from './container/ModalPermission'
import { get } from 'jquery'

function Profile({}) {
    const { id } = useParams()
    const [loading, setLoading] = React.useState(true)
    const [generalData, setGeneralData] = React.useState({})
    const [listPosts, setListPosts] = React.useState([])
    const [modalEdit, setModalEdit] = React.useState(false)
    const [modalPermission, setModalPermission] = React.useState(false)

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setValue,
        control,
    } = useForm({})

    const onSubmit = (data) => {
        data.user_id = id

        ServiceUsers.updateUser(id, data)
            .then((res) => {
                setModalEdit(false)
                toast.success('Perfil atualizado com sucesso!')
                onLoad()
            })
            .catch((err) => {
                console.log(err)
                if (err.response.status === 400) {
                    setError('user', {
                        type: 'manual',
                        message: 'Username já está usado',
                    })
                }
            })
    }

    const onLoad = async () => {
        setLoading(true)
        ServiceUsers.getUser(id)
            .then((res) => {
                setGeneralData(res.data)
                setValue('name', res.data.info.nome)
                setValue('user', res.data.info.user)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        if (id) onLoad()
    }, [])

    const HandlerReset = (type) => {
        ServiceUsers.updateReset(id, { type })
            .then((resp) => {
                toast.success('Imagem tirada com sucesso')
                onLoad()
            })
            .catch((err) => console.log(err))
    }

    const HandlerBanner = (status) => {
        if (status) {
            Swal.fire({
                title: 'Tem certeza?',
                text: 'Você não poderá reverter isso!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, bana!',
            }).then((result) => {
                if (result.value) {
                    ServiceUsers.updateBanned(id, true)
                        .then((resp) => {
                            toast.success('Usuario banido com sucesso')
                            onLoad()
                        })
                        .catch((err) => console.log(err))
                }
            })
        } else {
            Swal.fire({
                title: 'Tem certeza?',
                text: 'Você não poderá reverter isso!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, desbana!',
            }).then((result) => {
                if (result.value) {
                    ServiceUsers.updateBanned(id, false)
                        .then((resp) => {
                            toast.success('Usuario desbanido com sucesso')
                            onLoad()
                        })
                        .catch((err) => console.log(err))
                }
            })
        }
    }

    const HandlerLimited = (status) => {
        if (status) {
            Swal.fire({
                title: 'Tem certeza?',
                text: 'Você não poderá reverter isso!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, limite!',
            }).then((result) => {
                if (result.value) {
                    ServiceUsers.updateLimited(id, true)
                        .then((resp) => {
                            toast.success('Usuario limitado com sucesso')
                            onLoad()
                        })
                        .catch((err) => console.log(err))
                }
            })
        } else {
            Swal.fire({
                title: 'Tem certeza?',
                text: 'Você não poderá reverter isso!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, de liberdade!',
            }).then((result) => {
                if (result.value) {
                    ServiceUsers.updateLimited(id, false)
                        .then((resp) => {
                            toast.success('Retirada a limitação do usuario com sucesso')
                            onLoad()
                        })
                        .catch((err) => console.log(err))
                }
            })
        }
    }

    const getPermission = () => {
        if (generalData?.info?.is_adm) return 'admin'
        if (generalData?.info?.is_mod) return 'mod'
        return 'user'
    }

    return (
        <>
            <Title name={generalData?.info?.nome} />
            <div className="row">
                <div className="col-xl-3 col-lg-5">
                    {loading ? (
                        <RowLoading />
                    ) : (
                        <>
                            <AboutMe data={generalData} onEdit={() => setModalEdit(true)} />
                            <Options
                                banned={generalData.info.banido}
                                limited={!generalData.info.send_post}
                                onBanner={HandlerBanner}
                                onLimited={HandlerLimited}
                                onPermission={() => setModalPermission(true)}
                            />
                        </>
                    )}
                </div>

                <div className="col-xl-5 col-lg-7">
                    <Feed userId={id} />
                </div>

                <div className="col-xl-4">
                    <Graphics id={id} />
                </div>
                <CustomModal title="Editar" open={modalEdit} onClose={() => setModalEdit(false)}>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group>
                            <Form.Label>Nome</Form.Label>
                            <Controller name="name" control={control} render={({ field }) => <Form.Control {...field} />} />
                            <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>User</Form.Label>
                            <Controller name="user" control={control} render={({ field }) => <Form.Control {...field} />} />
                            <Form.Control.Feedback type="invalid">{errors.user?.message}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="d-flex flex-column">
                            <Form.Label>Resetar</Form.Label>
                            <ButtonGroup aria-label="Basic example">
                                <Button variant="secondary" onClick={() => HandlerReset('avatar')}>
                                    Avatar
                                </Button>
                                <Button variant="secondary" onClick={() => HandlerReset('cover')}>
                                    Capa
                                </Button>
                            </ButtonGroup>
                        </Form.Group>
                        <div className="d-flex justify-content-end">
                            <Button type="submit" variant="primary">
                                Salvar
                            </Button>
                        </div>
                    </Form>
                </CustomModal>
                <CustomModal open={modalPermission} title="Permissão" onClose={() => setModalPermission(false)}>
                    <ModalPermission
                        user_id={id}
                        permission={getPermission()}
                        onComplete={async () => {
                            await onLoad()
                            setModalPermission(false)
                        }}
                    />
                </CustomModal>
            </div>
        </>
    )
}

export default Profile

import React, { useEffect } from 'react'
import { useState } from 'react'
import Chart from 'react-apexcharts'
import { DateTime } from 'luxon'
import DatePick from '../../../../components/DatePicker'
import { Controller } from 'react-hook-form'
import { AiOutlineReload } from 'react-icons/ai'
// import { Container } from './styles';

function GraphicsLastAccount({ dataChart, onSubmit, control, handleSubmit }) {
    const [state, setState] = useState({
        options: {
            chart: {
                type: 'area',
                toolbar: {
                    show: false,
                },
            },
            stroke: {
                curve: 'smooth',
            },
            xaxis: {
                type: 'string',
                categories: [1, 2, 3, 4, 5, 6, 7, 8],
                tooltip: {
                    enabled: !1,
                },
                axisBorder: {
                    show: !1,
                },
                labels: {
                    show: false,
                    formatter: function (value) {
                        return DateTime.fromISO(value).toFormat('dd/LL/yyyy')
                    },
                },
            },
            legend: {
                show: !1,
            },
            dataLabels: {
                enabled: !1,
            },
            stroke: {
                curve: 'smooth',
                width: 4,
            },
        },
        series: [
            {
                name: 'Novos usuarios',
                data: [10, 20, 5, 15, 10, 20, 15, 25],
            },
        ],
    })

    useEffect(() => {
        let set_value = (dataChart || []).map((element) => element.total)
        let set_data = (dataChart || []).map((element) => DateTime.fromISO(element.date, { zone: 'utc' }).toISODate())
        let saida = {
            ...state,
            options: {
                ...state.options,
                xaxis: {
                    ...state.options.xaxis,
                    type: 'date',
                    categories: set_data,
                },
            },
            series: [{ ...state.series[0], data: set_value }],
        }
        setState(saida)
    }, [dataChart])

    return (
        <div className="card card-h-100">
            <div className="card-body">
                <div className="d-flex flex-row justify-content-between align-items-center mb-3">
                    <h4 className="header-title m-0">Numero de usuarios novos por dia</h4>
                    <form className="d-flex" onSubmit={handleSubmit(onSubmit)}>
                        <div className="input-group">
                            <Controller
                                name="date"
                                control={control}
                                render={({ field }) => (
                                    <DatePick
                                        start={field.value.created_from}
                                        end={field.value.created_to}
                                        onStart={(e) => field.onChange({ created_from: e, created_to: field.value.created_to })}
                                        onEnd={(e) => field.onChange({ created_from: field.value.created_from, created_to: e })}
                                    />
                                )}
                            />
                            <button class="input-group-text bg-primary text-white ml-2">
                                <AiOutlineReload />
                            </button>
                        </div>
                    </form>
                </div>

                <div>
                    <Chart options={state.options} series={state.series} width="100%" height="280px" />
                </div>
            </div>
        </div>
    )
}

export default GraphicsLastAccount

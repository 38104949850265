import React from 'react'
import { AiOutlineDashboard, AiOutlineUser, AiOutlineBell, AiOutlineComment, AiOutlineFileImage } from 'react-icons/ai'

// import { Container } from './styles';

function NavLink() {
    return (
        <div className="topnav shadow-sm">
            <div className="container-fluid active">
                <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
                    <div className="collapse navbar-collapse active" id="topnav-menu-content">
                        <ul className="navbar-nav">
                            <li className="nav-item dropdown active">
                                <a className="nav-link " href="/">
                                    <AiOutlineDashboard className="me-1" />
                                    Dashboards
                                </a>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link" href="/profile">
                                    <AiOutlineUser className="me-1" />
                                    Perfis
                                </a>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link" href="/notification">
                                    <AiOutlineBell className="me-1" />
                                    Notificação
                                </a>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link" href="/feed">
                                    <AiOutlineFileImage className="me-1" />
                                    Posts Recentes
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    )
}

export default NavLink

import React from 'react'
import Modal from '@material-ui/core/Modal'
import { FaTimes } from 'react-icons/fa'

// import { Container } from './styles';

function CustomModal({ onClose, open, children, title }) {
    return (
        <Modal onClose={onClose} open={open}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header modal-colored-header bg-primary">
                        <h4 className="modal-title" id="primary-header-modalLabel">
                            {title || 'Modal Heading'}
                        </h4>
                        <button type="button" onClick={onClose} className="btn-close">
                            <FaTimes />
                        </button>
                    </div>
                    <div className="modal-body">{children}</div>
                    {/* <div className="modal-footer">
                        <button type="button" className="btn btn-light" data-bs-dismiss="modal">
                            Close
                        </button>
                        <button type="button" className="btn btn-primary">
                            Save changes
                        </button>
                    </div> */}
                </div>
            </div>
        </Modal>
    )
}

export default CustomModal

import React, { useContext, useEffect } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import { ContextFeed } from '../..'

// import { Container } from './styles';

function Filter({ onSubmit }) {
    const { setFilter, filter } = useContext(ContextFeed)
    const {
        register,
        handleSubmit,
        watch,
        control,
        formState: { errors },
        setValue,
    } = useForm({
        defaultValues: {
            query: '',
            order: 'recents',
        },
    })

    useEffect(() => {
        if (filter) {
            Object.keys(filter).forEach((key) => setValue(key, filter[key]))
        }
    }, [filter])

    return (
        <div className="px-3">
            <h5>Filtros</h5>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group>
                    <Form.Label>Pesquisar</Form.Label>
                    <Controller name="query" control={control} render={({ field }) => <Form.Control type="text" placeholder="Pesquisar" {...field} />} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Ordem</Form.Label>
                    <Controller
                        name="order"
                        control={control}
                        render={({ field }) => (
                            <Form.Control as="select" {...field}>
                                <option value="recents">Mais recentes</option>
                                <option value="hot">Mais populares</option>
                            </Form.Control>
                        )}
                    />
                </Form.Group>
                <div>
                    <Button type="submit">Enviar</Button>
                </div>
            </Form>
        </div>
    )
}

export default Filter

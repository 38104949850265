import React from 'react'

function GeneralData({ total, active, recent }) {
    return (
        <div className="row">
            <div className="col-sm-4">
                <div className="card tilebox-one">
                    <div className="card-body">
                        <i className="dripicons-basket float-end text-muted" />
                        <h6 className="text-muted text-uppercase mt-0">Total de Usuarios</h6>
                        <h2 className="m-b-20">{total.toLocaleString('pt-BR')}</h2>
                        {/* <span className="badge bg-primary"> +11% </span> <span className="text-muted">From previous period</span> */}
                    </div>{' '}
                    {/* end card-body*/}
                </div>{' '}
                {/*end card*/}
            </div>
            {/* end col */}
            <div className="col-sm-4">
                <div className="card tilebox-one">
                    <div className="card-body">
                        <i className="dripicons-box float-end text-muted" />
                        <h6 className="text-muted text-uppercase mt-0">Usuarios novos hoje</h6>
                        <h2 className="m-b-20">
                            <span>{recent.toLocaleString('pt-BR')}</span>
                        </h2>
                        {/* <span className="badge bg-danger"> -29% </span> <span className="text-muted">From previous period</span> */}
                    </div>{' '}
                    {/* end card-body*/}
                </div>{' '}
                {/*end card*/}
            </div>
            {/* end col */}
            <div className="col-sm-4">
                <div className="card tilebox-one">
                    <div className="card-body">
                        <i className="dripicons-jewel float-end text-muted" />
                        <h6 className="text-muted text-uppercase mt-0">Usuarios ativos hoje</h6>
                        <h2 className="m-b-20">{active.toLocaleString('pt-BR')}</h2>
                        {/* <span className="badge bg-primary"> +89% </span> <span className="text-muted">Last year</span> */}
                    </div>{' '}
                    {/* end card-body*/}
                </div>{' '}
                {/*end card*/}
            </div>
            {/* end col */}
        </div>
    )
}

export default GeneralData

import React from 'react'

// import { Container } from './styles';

function CardDashboard({ title, value, percentage, percentage_label, icon }) {
    return (
        <div className="card tilebox-one">
            <div className="card-body">
                {icon}
                <h6 className="text-uppercase mt-0">{title}</h6>
                <h2 className="my-2 pt-1" id="active-users-count">
                    {value}
                </h2>
                {percentage && (
                    <p className="mb-0 text-muted">
                        <span className="text-success me-2">
                            <span className="mdi mdi-arrow-up-bold" /> {percentage}%
                        </span>
                        <span className="text-nowrap">{percentage_label}</span>
                    </p>
                )}
            </div>
        </div>
    )
}

export default CardDashboard

import React, { useEffect, useState } from 'react'
import { ServiceDashboard } from '../../../../services/api'
import { URL_FILES } from '../../../../services/axios.service'

// import { Container } from './styles';

function Posts() {
    const [feed, setFeed] = useState([])
    useEffect(() => {
        ServiceDashboard.getLastPosts()
            .then((resp) => {
                setFeed(resp.data)
            })
            .catch((err) => {})
    }, [])
    return (
        <div className="card">
            <div className="card-body">
                <h4 className="header-title mb-2">Posts Recentes</h4>
                {feed.map((element) => (
                    <div className="media text-muted pt-3">
                        <a href={'/post/' + element.id_post}>
                            <img className="mr-2 rounded" style={{ width: 55, height: 55 }} src={URL_FILES + '/posts/' + element.midia} />
                        </a>

                        <p className="media-body pb-3 mb-0 small lh-125 border-bottom border-gray" style={{ minHeight: '40px' }}>
                            <a href={'/profile/' + element.id_autor}>
                                <strong className="d-block text-gray-dark">@{element.user}</strong>
                            </a>
                            {element.titulo}
                        </p>
                    </div>
                ))}

                <small className="d-block text-right mt-3">
                    <a href="#">Todos os posts</a>
                </small>
            </div>
        </div>
    )
}

export default Posts

import { setInsideStack, setOutSideStack } from '../redux/actions'
import store from '../redux/store'
import { ServiceAuth } from '../services/api/auth'
import { application } from '../services/axios.service'
import device from './device'

class Session {
    async init() {
        const jwt_token = localStorage.getItem('@memehutAdmin:jwtToken')
        const user = localStorage.getItem('@memehutAdmin:user')
        if (jwt_token && user) {
            application.defaults.headers.Authorization = `Bearer ${jwt_token}`
            application.defaults.headers['x-device'] = device.init()
            ServiceAuth.check()
                .then((resp) => {
                    store.dispatch(setInsideStack(JSON.parse(user), jwt_token))
                })
                .catch((err) => {
                    store.dispatch(setOutSideStack())
                })
        } else {
            store.dispatch(setOutSideStack())
        }
    }

    async set(token, user) {
        application.defaults.headers.Authorization = `Bearer ${token}`
        application.defaults.headers['x-device'] = device.init()
        localStorage.setItem('@memehutAdmin:jwtToken', token)
        localStorage.setItem('@memehutAdmin:user', JSON.stringify(user))
    }

    async get() {
        const jwt_token = localStorage.getItem('@memehutAdmin:jwtToken')
        const user = localStorage.getItem('@memehutAdmin:user')
        return {
            jwt_token,
            user,
        }
    }
}

export default new Session()

import { DateTime } from 'luxon'
import React from 'react'
import { FaCommentAlt, FaSmile } from 'react-icons/fa'
import { URL_BASE, URL_FILES } from '../../../../services/axios.service'

// import { Container } from './styles';

function Post({ post }) {
    return (
        <div className="card">
            <div className="card-body pb-1">
                <div className="d-flex">
                    <img
                        className="me-2 rounded"
                        src={post?.avatar ? URL_FILES + '/user/' + post.avatar : URL_BASE + '/images/pepeavatar.png'}
                        alt="Generic placeholder image"
                        height={32}
                    />
                    <div className="w-100">
                        <div className="dropdown float-end text-muted"></div>
                        <a href={'/profile/' + post.id_autor}>
                            <h5 className="m-0">{post.user}</h5>
                        </a>

                        <p className="text-muted">
                            <small>{DateTime.fromSQL(post.data_criacao).toLocaleString(DateTime.DATETIME_SHORT)}</small>
                        </p>
                    </div>
                </div>
                <hr className="m-0" />
                <div className="my-3">
                    {post.titulo && <b>{post.titulo}</b>}
                    {post.descricao && <p>{post.descricao}</p>}
                    <div className="row">
                        <div className="col-12">
                            <a href={'/post/' + post.id_post}>
                                <img src={URL_FILES + '/posts/' + post.midia} alt="post-img" className="rounded me-1 mb-3 mb-sm-0 img-fluid" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-between">
                    <div className="mt-1 mb-1 d-flex flex-row">
                        <a href="javascript: void(0);" className="btn btn-sm btn-link text-muted ps-0 d-flex align-items-center flex-row">
                            <FaSmile className="mr-1" /> {post.n_likes} Likes
                        </a>
                        <a href="javascript: void(0);" className="btn btn-sm btn-link text-muted">
                            <FaCommentAlt className="mr-1" /> {post.n_comentarios} Comentarios
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Post

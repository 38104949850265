import * as actionTypes from './actionTypes'
import { combineReducers } from 'redux'

const INITIAL_APP_STATE = {
    root: actionTypes.ROOT_LOADING,
    user: null,
}

const appReducer = (state = INITIAL_APP_STATE, action) => {
    switch (action.type) {
        case actionTypes.LOGIN: {
            return {
                ...state,
                user: action.payload.user,
                token: action.payload.token,
                root: actionTypes.ROOT_INSIDE,
            }
        }

        case actionTypes.LOGOUT: {
            return {
                ...state,
                user: null,
                root: actionTypes.ROOT_OUTSIDE,
            }
        }
        default:
            return state
    }
}

const rootReducer = combineReducers({
    app: appReducer,
})

export default rootReducer

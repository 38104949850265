import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import RowLoading from '../../components/RowLoading'
import { ServiceUsers } from '../../services/api/users'
import { URL_FILES } from '../../services/axios.service'
import GeneralData from './container/GeneralData/index'
import GraphicsLastAccount from './container/Graphic'
import TabBar from './container/TabBar'

function Profile() {
    const [generalData, setGeneralData] = useState({})
    const [listUsers, setListUsers] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadingGraphics, setLoadingGraphics] = useState(true)
    const [graphic, setGraphic] = useState([])

    const { register, handleSubmit, watch, errors, control } = useForm({
        defaultValues: {
            date: {
                created_from: DateTime.now().plus({ day: -7 }).toJSDate(),
                created_to: DateTime.now().toJSDate(),
            },
        },
    })

    const loadGraphic = async (date = { created_from: DateTime.now().plus({ day: -7 }).toJSDate(), created_to: DateTime.now().toJSDate() }) => {
        setLoadingGraphics(true)
        ServiceUsers.getGraphicalData({ created_from: DateTime.fromJSDate(date.created_from).toSQLDate(), created_to: DateTime.fromJSDate(date.created_to).toSQLDate() })
            .then((resp) => {
                setGraphic(resp.data)
                setLoadingGraphics(false)
            })
            .catch((err) => console.log(err))
    }

    const onSubmit = (data) => {
        loadGraphic(data.date)
    }

    useEffect(() => {
        loadGraphic()
        ServiceUsers.getGeneralData()
            .then((resp) => {
                setGeneralData(resp.data)
                return ServiceUsers.getUsers({ limit: 10, offset: 0 })
            })
            .then((resp) => {
                setLoading(false)
                setListUsers(resp.data.users)
            })
            .catch((err) => console.log(err))
    }, [])

    return (
        <div>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box">
                        <h4 class="page-title">Perfis</h4>
                    </div>
                </div>
            </div>

            {loading ? <RowLoading /> : <GeneralData total={generalData.total} active={generalData.active} recent={generalData.recent} />}
            {loadingGraphics ? (
                <RowLoading />
            ) : (
                <div className="row">
                    <div className="col-12">
                        <GraphicsLastAccount dataChart={graphic} onSubmit={onSubmit} control={control} handleSubmit={handleSubmit} />
                    </div>
                </div>
            )}

            <TabBar list={listUsers} />
        </div>
    )
}

export default Profile

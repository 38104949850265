import React, { useEffect } from 'react'
import { useState } from 'react'
import Chart from 'react-apexcharts'
import { DateTime } from 'luxon'
// import { Container } from './styles';

function Sessions({ dataChart }) {
    const [state, setState] = useState({
        options: {
            chart: {
                type: 'area',
                toolbar: {
                    show: false,
                },
            },
            stroke: {
                curve: 'smooth',
            },
            xaxis: {
                type: 'string',
                categories: [1, 2, 3, 4, 5, 6, 7, 8],
                tooltip: {
                    enabled: !1,
                },
                axisBorder: {
                    show: !1,
                },
                labels: {
                    show: false,
                    formatter: function (value) {
                        return DateTime.fromISO(value).toFormat('dd/LL/yyyy')
                    },
                },
            },
            legend: {
                show: !1,
            },
            dataLabels: {
                enabled: !1,
            },
            stroke: {
                curve: 'smooth',
                width: 4,
            },
        },
        series: [
            {
                name: 'Posts',
                data: [10, 20, 5, 15, 10, 20, 15, 25],
            },
        ],
    })

    useEffect(() => {
        let set_value = (dataChart || []).map((element) => element.total)
        let set_data = (dataChart || []).map((element) => DateTime.fromISO(element.date, { zone: 'utc' }).toISODate())
        let saida = {
            ...state,
            options: {
                ...state.options,
                xaxis: {
                    ...state.options.xaxis,
                    type: 'date',
                    categories: set_data,
                },
            },
            series: [{ ...state.series[0], data: set_value }],
        }
        console.log(saida)
        setState(saida)
    }, [dataChart])

    return (
        <div className="card card-h-100">
            <div className="card-body">
                <h4 className="header-title mb-3">Numero de posts por dia</h4>
                <div>
                    <Chart options={state.options} series={state.series} width="100%" height="280px" />
                </div>
            </div>
        </div>
    )
}

export default Sessions

import React from 'react'
import { FaEdit, FaTrash } from 'react-icons/fa'

// import { Container } from './styles';

function Options({ handlerEdit, handleDelete }) {
    return (
        <div className="card">
            <div className="card-body p-2">
                <h5 className="ml-3 mb-3">Opções</h5>
                <div className="list-group list-group-flush my-2">
                    <a href="javascript:void(0);" onClick={handlerEdit} className="list-group-item list-group-item-action border-0">
                        <FaEdit size={15} className="me-1" /> Editar
                    </a>
                    <a href="javascript:void(0);" onClick={handleDelete} className="list-group-item list-group-item-action border-0 text-danger">
                        <FaTrash size={15} className="me-1" /> Apagar
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Options

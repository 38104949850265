import { DateTime } from 'luxon'
import React from 'react'
import { URL_BASE, URL_FILES } from '../../services/axios.service'

// import { Container } from './styles';

function AboutMe({ info }) {
    return (
        <>
            <div className="card text-center">
                <div className="card-body">
                    <img
                        src={info?.avatar ? URL_FILES + '/user/' + info.avatar : URL_BASE + '/images/pepeavatar.png'}
                        className="rounded-circle avatar-lg img-thumbnail"
                        alt="profile-image"
                        style={{ maxWidth: '150px' }}
                    />
                    <h4 className="mb-0 mt-2">{info.nome}</h4>
                    <p className="text-muted font-14">@{info.user}</p>
                    <div className="text-start mt-3">
                        <h4 className="font-13 text-uppercase">Sobre :</h4>
                        <p className="text-muted mb-2 font-13">
                            <strong>Hutcoins:</strong> <span className="ms-2">{info.coin}</span>
                        </p>
                        <p className="text-muted mb-2 font-13">
                            <strong>É admin ?:</strong> <span className="ms-2">{Boolean(info.is_adm) ? 'Sim' : 'Não'}</span>
                        </p>
                        <p className="text-muted mb-2 font-13">
                            <strong>É moderador ?:</strong> <span className="ms-2">{Boolean(info.is_mod) ? 'Sim' : 'Não'}</span>
                        </p>
                        <p className="text-muted mb-2 font-13">
                            <strong>Está banido ? :</strong> <span className="ms-2">{Boolean(info.banido) ? 'Sim' : 'Não'}</span>
                        </p>
                        <p className="text-muted mb-2 font-13">
                            <strong>Está limitado ?:</strong> <span className="ms-2">{Boolean(!info.send_post) ? 'Sim' : 'Não'}</span>
                        </p>
                        <p className="text-muted mb-1 font-13">
                            <strong>Data criação da conta :</strong> <span className="ms-2">{DateTime.fromSQL(info.data_criacao).toFormat('dd/LL/yyyy hh:mm:ss')}</span>
                        </p>
                        <p className="text-muted mb-1 font-13">
                            <strong>Data ultimo acesso :</strong> <span className="ms-2">{DateTime.fromSQL(info.data_ultimo_login).toFormat('dd/LL/yyyy hh:mm:ss')}</span>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutMe

import React, { useEffect } from 'react'
import { Button, Form } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { ServiceUsers } from '../../../../services/api'
import CustomButton from '../../../../components/CustomButton'

function ModalPermission({ user_id, permission, onComplete }) {
    const [saving, setSaving] = React.useState(false)
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setValue,
        control,
    } = useForm({ defaultValues: { permission: 'user' } })

    useEffect(() => {
        if (permission) setValue('permission', permission)
    }, [permission])

    const onSubmit = (data) => {
        // return alert(JSON.stringify(data))
        setSaving(true)
        ServiceUsers.setPermission(user_id, data)
            .then((resp) => {
                toast.success('Permissão alterada com sucesso!')
                onComplete()
            })
            .catch((err) => {
                toast.error('Não foi possível alterar a permissão!')
            })
            .finally(() => setSaving(false))
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
                <Form.Label>Permissão</Form.Label>
                <Controller
                    name="permission"
                    control={control}
                    render={({ field, meta }) => (
                        <Form.Control as="select" {...field}>
                            <option value="admin">Admin</option>
                            <option value="mod">Moderador</option>
                            <option value="user">Usuário</option>
                        </Form.Control>
                    )}
                />
            </Form.Group>
            <div>
                <CustomButton variant="primary" type="submit" loading={saving} disabled={saving}>
                    Salvar
                </CustomButton>
            </div>
        </Form>
    )
}

export default ModalPermission

import { application } from '../axios.service'

export const ServiceUsers = {
    getGeneralData: () => application.get('/users/general-data'),
    getUsers: (params) => application.get('/users', { params }),
    getUser: (id) => application.get('/users/' + id),
    updateUser: (id, data) => application.put('/users/' + id, data),
    updateReset: (id, data) => application.put('/users/' + id + '/reset', data),
    updateBanned: (id, status) => application.post('/users/' + id + '/banned', { status }),
    updateLimited: (id, status) => application.post('/users/' + id + '/limited', { status }),
    getGraphic: (id, params) => application.get(`/users/${id}/graphic`, { params }),
    getGraphicalData: (params) => application.get('/users/recent', { params }),
    setPermission: (id, data) => application.post(`/users/${id}/permission`, data),
}

import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import './styles.scss'
import { Form } from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid'
import CustomButton from '../../components/CustomButton'
import { ServiceAuth } from '../../services/api/auth'
import session from '../../utils/session'
import store from '../../redux/store'
import { setInsideStack } from '../../redux/actions'
import device from '../../utils/device'
import Logo from '../../assets/images/icone_quadrado.png'

const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
})

function Login() {
    const [saving, setSaving] = useState(false)
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        control,
        setError,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {},
        shouldUnregister: false,
    })

    const onSubmit = (data) => {
        setSaving(true)
        data.device_uuid = device.init()
        console.log(data.device_uuid)
        ServiceAuth.login(data)
            .then(async (resp) => {
                await session.set(resp.data.token, resp.data.user)
                store.dispatch(setInsideStack(resp.data.user, resp.data.token))
            })
            .catch((err) => {
                console.log(err)
                setError('password', { type: 'manual', message: 'Email e senha incorretos' })
            })
            .finally(() => setSaving(false))
    }

    return (
        <div className="text-center container">
            <Form className="form-signin" onSubmit={handleSubmit(onSubmit)}>
                <img className="mb-4" src={Logo} alt="logo" width={72} height={72} />
                <h1 className="h3 mb-3 font-weight-normal">Administração</h1>
                <label htmlFor="inputEmail" className="sr-only">
                    Email address
                </label>
                <Controller control={control} name="email" render={({ field }) => <Form.Control {...field} placeholder="Email" type="email" isInvalid={errors.password} />} />
                <label htmlFor="inputPassword" className="sr-only">
                    Password
                </label>
                <Controller control={control} name="password" render={({ field }) => <Form.Control {...field} placeholder="Senha" type="password" isInvalid={errors.password} />} />
                <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
                <div className="mt-4">
                    <CustomButton className="d-block w-100" loading={saving} type="submit">
                        Continuar
                    </CustomButton>
                </div>
                <p className="mt-5 mb-3 text-muted">© 2018-2021</p>
            </Form>
        </div>
    )
}

export default Login

import { application } from '../axios.service'

export const ServicePosts = {
    getPost: (id) => application.get(`/post/${id}`),
    getPostsUser: (id, params) => application.get(`/posts/user/${id}`, { params }),
    getPosts: (params) => application.get('/posts', { params }),
    delete: (id) => application.delete(`/post/${id}`),
    edit: (id, data) => application.put(`/post/${id}`, data),
    update: (id, data) => application.put(`/post/${id}`, data),
}

import React from 'react'
import Switch from 'react-bootstrap/esm/Switch'
import { Route } from 'react-router'
import Login from '../pages/Login'

// import { Container } from './styles';

function OutsideStack() {
    return (
        <Switch>
            <Route path="/" component={Login} />
        </Switch>
    )
}

export default OutsideStack

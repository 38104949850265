import { Chip } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import { DateTime } from 'luxon'
import React, { useEffect } from 'react'
import { Form, Button } from 'react-bootstrap'
import { FaCommentAlt, FaEdit, FaFilter, FaSmile, FaTrash } from 'react-icons/fa'
import RowLoading from '../../components/RowLoading'
import CustomModal from '../../components/CustomModal'

import { ServiceDashboard, ServicePosts } from '../../services/api'
import { URL_BASE, URL_FILES } from '../../services/axios.service'
import Filter from './container/Filter'
import Post from './container/Post'

const ContextFeed = React.createContext()

const LIMIT = 10

const dictFilter = {
    recents: 'Recentes',
    hot: 'Mais Curtidas',
}

function Feed() {
    const [posts, setPosts] = React.useState([])
    const [total, setTotal] = React.useState(0)
    const [page, setPage] = React.useState(0)
    const [loading, setLoading] = React.useState(false)
    const [filter, setFilter] = React.useState({ order: 'recents' })
    const [modalFilter, setModalFilter] = React.useState(false)

    const loadPosts = async (pageNumber = page, filterApplicate = filter) => {
        setLoading(true)

        const payload = {
            offset: pageNumber * LIMIT,
            limit: LIMIT,
            order: filterApplicate.order || 'recents',
            query: filterApplicate.query || undefined,
        }
        ServicePosts.getPosts(payload)
            .then((res) => {
                setPosts(res.data.posts)
                setTotal(res.data.total)
                setPage(pageNumber + 1)
                setLoading(false)
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => loadPosts(0), [])

    const handleChange = async (e, page) => {
        await loadPosts(page - 1)
    }

    const handlerSelectFilter = async (data) => {
        setFilter(data)
        await loadPosts(0, data)
        setModalFilter(false)
    }

    return (
        <ContextFeed.Provider value={{ filter, setFilter }}>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box">
                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">
                                    <a href="javascript: void(0);">Dashboard</a>
                                </li>
                                <li className="breadcrumb-item active">Posts Recentes</li>
                            </ol>
                        </div>
                        <h4 className="page-title">Feed</h4>
                    </div>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-6 offset-md-3 d-flex flex-row justify-content-between">
                    <div>
                        {filter?.query && (
                            <Chip label={'Pesquisa : ' + filter?.query} variant="outlined" onDelete={() => handlerSelectFilter({ ...filter, query: undefined })} className="mr-1" />
                        )}
                        {filter?.order && (
                            <Chip
                                label={'Ordenação : ' + dictFilter[filter.order]}
                                variant="outlined"
                                onDelete={() => handlerSelectFilter({ ...filter, order: undefined })}
                                className="mr-1"
                            />
                        )}
                    </div>
                    <Button onClick={() => setModalFilter(true)}>
                        <FaFilter /> Filtros
                    </Button>
                </div>
            </div>
            <div className="row">
                <div className="col-3"></div>
                <div className="col-6">
                    {loading ? (
                        <RowLoading />
                    ) : (
                        <>
                            {posts.map((post) => (
                                <Post post={post} />
                            ))}
                        </>
                    )}
                    <div className="card">
                        <div className="card-body">
                            <Pagination count={Math.ceil(total / LIMIT)} page={page} onChange={handleChange} />
                        </div>
                    </div>
                </div>
                <div className="col-3"></div>
                <CustomModal open={modalFilter} onClose={() => setModalFilter(false)} title="Filtro">
                    <Filter onSubmit={handlerSelectFilter} />
                </CustomModal>
            </div>
        </ContextFeed.Provider>
    )
}
export { ContextFeed }
export default Feed
